const Loader = {
	on: function(){
		$('#loading-page').addClass('active');
	},
	off: function(){
		$('#loading-page').removeClass('active');
	}
};

window.Loader = Loader;

export default Loader;