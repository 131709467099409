
$('#form-newsletter').on('submit', function(event) {
	event.preventDefault();
	const $url = 'index.php?route=extension/module/newsletter';

	var form = this,
		botao = $(form).find("input[type='submit'], input[type='image'], button[type='submit']");
	if (form.nome && form.nome.value == '') {form.nome.focus();return false;}
	if (form.email.value == '') {form.email.focus();return false;}

	function _campos() {
		return $(form).find(`
			input[type="text"], 
			input[type="email"], 
			input[type="hidden"],
			input[type="radio"]:checked
		`);
	}


	jQuery.ajax({
		url: $url,
		type: 'post',
		dataType: 'json',
		data: _campos(),
		beforeSend: function() {
			botao.attr('disabled', true);
		},
		complete: function() {
			botao.attr('disabled', false);
		},
		success: function(data) {
			if (data['erro']) {
				window.alert(data['erro']);
			}
			if (data['sucesso']) {
				window.alert(data['sucesso']);
				form.email.value = "";
				if (form.nome) {
					form.nome.value = "";
				}
			}
		}
	});
	return false;
});