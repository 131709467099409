// Endereco
var sistemas = '';
var sistemas = $('div[data-page="address"]');
if (sistemas.length>0){

    $('#address .col-lg-6[data-sort]').detach().each(function() {
        if ($(this).attr('data-sort') >= 0 && $(this).attr('data-sort') <= $('#address .col-lg-6').length-2) {
            $('#address .col-lg-6').eq(parseInt($(this).attr('data-sort'))+2).before(this);
        }

        if ($(this).attr('data-sort') > $('#address .col-lg-6').length-2) {
            $('#address .col-lg-6:last').after(this);
        }

        if ($(this).attr('data-sort') == $('#address .col-lg-6').length-2) {
            $('.form-group:last').after(this);
        }

        if ($(this).attr('data-sort') < -$('#address .col-lg-6').length-2) {
            $('#address .col-lg-6:first').before(this);
        }
    });

    $('select[name=\'country_id\']').on('change', function() {
        $.ajax({
            url: 'index.php?route=account/account/country&country_id=' + this.value,
            dataType: 'json',
            beforeSend: function() {
                $('select[name=\'country_id\']').after(' <i class="fa fa-circle-o-notch fa-spin"></i>');
            },
            complete: function() {
                $('.fa-spin').remove();
            },
            success: function(json) {
                if (json['postcode_required'] == '1') {
                    $('input[name=\'postcode\']').parent().parent().addClass('required');
                } else {
                    $('input[name=\'postcode\']').parent().parent().removeClass('required');
                }

                html = '<option value=""><?php echo $text_select; ?></option>';

                if (json['zone'] && json['zone'] != '') {
                    for (i = 0; i < json['zone'].length; i++) {
                        html += '<option value="' + json['zone'][i]['zone_id'] + '"';

                        if (json['zone'][i]['zone_id'] == $('select[name=\'zone_id\']').data('zone')) {
                            html += ' selected="selected"';
                        }

                        html += '>' + json['zone'][i]['name'] + '</option>';
                    }
                } else {
                    html += '<option value="0" selected="selected"><?php echo $text_none; ?></option>';
                }

                $('select[name=\'zone_id\']').html(html);
            },
            error: function(xhr, ajaxOptions, thrownError) {
                alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    });

    $('select[name=\'country_id\']').trigger('change');

    jQuery("input[name='postcode']").blur(function() {
        var cep = this.value.replace(/\D/g, '');
        var valido = (cep.length == 8);

        if (valido) {
            $('#address .text-danger').remove();
            $.ajax({
                url: "https://viacep.com.br/ws/" + cep + "/json/",
                type: 'get',
                dataType: 'json',
                timeout: 5000,
                beforeSend: function(){
                    $('input[name="address_1"], input[name="address_2"], input[name="city"], select[name="zone_id"]').attr('disabled', true);
                    $("input[name='postcode']").before(' <i class="fas fa-circle-notch fa-spin"></i> ');
                },
                complete: function(){
                    $('input[name="address_1"], input[name="address_2"], input[name="city"], select[name="zone_id"]').attr('disabled', false);
                    $('.fa-spin').remove();
                },
                success: function(json) {
                    if (json['message']) {
                        $("input[name='postcode']").after('<span class="text-danger">'+json['message']+'</span>');
                    } else {
                        $('input[name="address_1"]').val(json['logradouro']);
                        $('input[name="address_2"]').val(json['bairro']);
                        $('input[name="city"]').val(json['localidade']);
                        $("select[name='zone_id'] option[title='"+json["uf"]+"']").attr('selected', true);
                    }
                    var inputs = $('#address .col-lg-6:visible input'),
                        total = inputs.length, i=0;
                    for (i=0; i<total; i++) {
                        if (inputs[i].value == "") {
                            inputs[i].focus();
                            break;
                        }
                    }
                }
            });
        }
    });
}