var sistemas = $('.alterar__interna');
if (sistemas.length>0){
    $('.col-lg-6[data-sort]').detach().each(function() {
        if ($(this).attr('data-sort') >= 0 && $(this).attr('data-sort') <= $('.form-group').length) {
            $('.form-group').eq($(this).attr('data-sort')).before(this);
        }

        if ($(this).attr('data-sort') > $('.form-group').length) {
            $('.form-group:last').after(this);
        }

        if ($(this).attr('data-sort') == $('.form-group').length) {
            $('.form-group:last').after(this);
        }

        if ($(this).attr('data-sort') < -$('.form-group').length) {
            $('.form-group:first').before(this);
        }
    });
}