$('[data-toggle="tooltip"]').tooltip({'container': 'body'});

$('a.scrollIntoView').on('click', function(e) {
    e.preventDefault();
    var alvo = $(this).attr('href');
    var scrllTo = $(alvo).offset().top;

    $('body,html').animate({scrollTop: scrllTo},1000);
});



$('#categorias-lateral li').on('click', function(){
    var url = $(this).data('value');
    if (url != "") {
        location = url;
    }
});
var sistemas = $('#filters input[type="checkbox"], #filters input[type="radio"]');
var action = $('#filters').data('action');
if (sistemas.length>0){
    $('#filters input[type="checkbox"], #filters input[type="radio"]').on('change', function() {
        filter = [];

        $('#filters input[type=\'checkbox\']:checked, #filters input[type=\'radio\']:checked').each(function(element) {
            filter.push(this.value);
        });

        var url = action;

        url += (url.indexOf('?')>-1)?'&':'?';

        location = url + 'filter=' + filter.join(',');
    });
}