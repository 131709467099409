/**
 * 
 * @param {string} prop - Nome da proprieda do window que nos dirá se o script existe ou não
 * @param {Function} callback - Função que será executada assim q o js terminar de carregar
 * @param {string} scriptUrl - Url do script a ser carregado. Ex: 'https://cdn.js/owl.carousel.min.js'
 */
function verificaSeScriptExiste ({prop, callback, scriptUrl}) {

    if(!window[prop]) {
        const js = document.createElement('script');

        js.async = true;
        js.src = scriptUrl;

        js.addEventListener('load', function() {
            callback();
        });

        window[prop] = true;

        document.body.appendChild(js);

    }else {
        callback();
    }
}

export default verificaSeScriptExiste;