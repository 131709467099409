const notification = {
	alert: function(msg, type, icon, append) {
		type = type || 'info';
		icon = icon || '<i class="fa fa-check-circle"></i>';
		var html = '<div class="alert alerta-'+type+'">'+icon+' ' + msg + '<i class="fa fa-times close" onclick="jQuery(this).parent().fadeOut();"></i></div>';
		if (append === true) {
			jQuery('#notification').append(html);
		} else {
			jQuery('#notification').html(html);
		}
		jQuery('#notification .alertas').fadeIn('slow');
		// jQuery('html, body').animate({ scrollTop: 0 }, 'slow');
	},
	success: function(msg, append) {
		return notification.alert(msg, 'success', '<i class="fa fa-check-circle"></i>', append);
	},
	error: function(msg, append) {
		return notification.alert(msg, 'danger', '<i class="fa fa-exclamation-triangle"></i>', append);
	}
};

window.notification = notification;

export default notification;